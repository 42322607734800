import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'i18n'
import 'core-js/features/array/index'
import Cookies from 'js-cookie'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  Switch,
  VisuallyHidden
} from '@chakra-ui/react'
import css from 'styled-jsx/css'
import HTMLWrapper from 'lib/html-wrapper'

import CheckCircle from 'static/assets/images/check-circle.png'

const { className, styles } = css.resolve`
  .cookie-consent {
    height: auto;
    width: 100%;
    bottom: 0px;
    position: fixed;
    background-color: white;
    padding: 2rem;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);
    z-index: 999;
  }

  .row {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-top: 10px;
  }

  .col-80 {
    width: 80%;
  }

  .confirm-button {
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    color: white;
  }

  .secondary-button {
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    background-color: white;
    color: #595959;
    border: 1px solid #D9D9D9;
  }
  
  .toggle-switch {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .cookies {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .manage-cookies {
    font-size: 16px;
    font-weight: bold;
    color: #262626;
  }
  
  .modal-content {
    color: black;
    background-color: white !important;
  }

  .active-cookie {
    color: #262626;
  }

  .inactive-cookie {
    color: #8C8C8C;
    -webkit-user-select: none;
    -moz-user-select: none;     
    -ms-user-select: none;
    user-select: none;
  }

  .cookie-description {
    font-size: 14px;
  }

  .cookie-review {
    color: #262626;
    font-size: 14px;
  }

  .disabled-image {
    height: 32px;
  }
`

const CookiePolicy = ({
  t,
  isFacebookSdkEnabled,
  isTwitterWidgetEnabled,
  isUserTrackingAnalyticsEnabled,
  setCookieBoxVisiblity
}) => {
  const isSocialAnalyticsEnabled = isTwitterWidgetEnabled || isFacebookSdkEnabled

  const [isModalVisible, setModalVisibility] = useState(false)
  const [isAnalyticsCookieEnabled, setAnalyticsCookie] = useState(isUserTrackingAnalyticsEnabled)
  const [isSocialCookieEnabled, setSocialCookie] = useState(isSocialAnalyticsEnabled)

  const focusRef = useCallback((node) => {
    if (node) node?.querySelector("a")?.focus()
  }, []);


  const cookiesList = [
    { name: 'essential', status: true },
    { name: 'analytics', status: isUserTrackingAnalyticsEnabled },
    { name: 'socialMedia', status: isSocialAnalyticsEnabled }
  ]

  const toggleCookieModal = () => setModalVisibility(!isModalVisible)

  const setCookieConsent = () => {
    const currentDate = new Date()

    // Creating a expiry date for the cookie which is exactly after two years
    currentDate.setMonth(currentDate.getMonth() + 24)

    const cookieObject = {
      functional: true,
      analytics: isAnalyticsCookieEnabled,
      social: isSocialCookieEnabled
    }

    Cookies.set('cookie_consent', cookieObject, { expires: currentDate })

    setModalVisibility(!isModalVisible)
    setCookieBoxVisiblity(false)
  }

  const isChecked = (cookieName) => {
    switch (cookieName) {
      case 'essential': return true
      case 'analytics': return isAnalyticsCookieEnabled
      case 'socialMedia': return isSocialCookieEnabled

      default: return false
    }
  }

  const modalNode = () => {
    if (isModalVisible) return false

    return (
      <div className={`${className} cookie-consent`} ref={focusRef}>
        <h2 className={`${className} manage-cookies`}>{t('common:common.cookies.descriptionOne')}</h2>
        <p className='ehq-mt-2'>
          <HTMLWrapper html={t('common:common.cookies.descriptionTwo')} />
        </p>
        <div className='ehq-mt-6'>
          <Button colorScheme='blue' className={`${className} confirm-button`} onClick={setCookieConsent}>
            {t('common:common.cookies.cookieConfirm')}
          </Button>
          <Button colorScheme='blue' variant='outline' className={`${className} secondary-button`} onClick={toggleCookieModal}>
            {t('common:common.cookies.manageCookies')}
          </Button>
        </div>
      </div>
    )
  }

  const handleChange = (cookieName, value) => {
    if (cookieName === 'analytics') {
      setAnalyticsCookie(value)
    } else {
      setSocialCookie(value)
    }
  }

  const renderToggle = (cookie) => {
    if (cookie.name === 'essential') {
      return (
        <img
          alt='check-circle'
          className={`${className} disabled-image`}
          src={CheckCircle}
          aria-hidden='true'
        />
      )
    }

    return (
      <>
        <VisuallyHidden>
          {t(`common:common.cookies.${cookie.name}Cookies`)}
        </VisuallyHidden>
        <Switch
          isChecked={isChecked(cookie.name)}
          isDisabled={!cookie.status}
          className={`${className} switch`}
          onChange={e => handleChange(cookie.name, e.target.checked)}
        />
      </>
    )
  }

  const renderCookieReview = () => {
    const analyticsCookie = cookiesList[1].status
    const socialMediaCookie = cookiesList[2].status

    if (!analyticsCookie && !socialMediaCookie) {
      return (
        <p className={`${className} ehq-mt-4 cookie-review`}>{t('common:common.cookieReview')}</p>
      )
    }
  }

  return (
    <>
      {modalNode()}
      <Modal
        isOpen={isModalVisible}
        onClose={toggleCookieModal}
        blockScrollOnMount
        size='xl'
      >
        <ModalContent className={`${className} modal-content`}>
          <ModalBody>
            <div className='md:ehq-px-10 md:ehq-pt-10 md:ehq-pb-6 sm:ehq-p-1 ehq-p-4'>
              <h2 className={`${className} manage-cookies`}>{t('common:common.cookies.manageCookies')}</h2>
              <p className={`${className} ehq-mt-1 cookie-description`}>
                <HTMLWrapper html={t('common:common.cookies.manageCookiesDescription')} />
              </p>
              {cookiesList.map((cookie, index) => {
                return (
                  <div key={index} className={`${className} ${cookie.status ? 'active-cookie' : 'inactive-cookie'}`} title={`${cookie.status ? '' : t('common:common.cookies.disbledTooltip')}`}>
                    <div className={`${className} row`}>
                      <div className={`${className} col-80`}>
                        <p className={`${className} cookies`}>{t(`common:common.cookies.${cookie.name}Cookies`)}</p>
                        <p className={`${className} ehq-mt-1 cookie-description`}>{t(`common:common.cookies.${cookie.name}CookiesDescription`)}</p>
                      </div>
                      <div className={`${className} toggle-switch col-20`}>
                        {renderToggle(cookie)}
                      </div>
                    </div>
                    <hr className='ehq-mt-4' />
                  </div>
                )
              }
              )}
              {renderCookieReview()}
              <div className='ehq-mt-6'>
                <Button key='save' className={`${className} confirm-button`} onClick={setCookieConsent}>
                  {t('common:common.cookies.save&Close')}
                </Button>
                <Button key='cancel' className={`${className} secondary-button`} onClick={toggleCookieModal}>
                  {t('common:common.cancel')}
                </Button>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <style jsx>
        {
          `
            /* Pointer cursor is added as it does not appear on switch component of Chakra in IE. */
            input[type=checkbox] + div {
              cursor: pointer;
            }

            /* Default color of switch is overridden with other background color */
            input[type=checkbox]:checked + div {
              background-color: #52C41A !important;
            }

            /* calc() function with translateX does not work in IE for switch, below code fixes the switch toggle animation */
            input[type=checkbox]:checked + div > *, input[type=checkbox][aria-checked=mixed] + div > * {
              transform: translateX(90%) !important;
            }
          `
        }
      </style>
      {styles}
    </>
  )
}

CookiePolicy.propTypes = {
  t: PropTypes.func.isRequired,
  isFaceBookSdkEnabled: PropTypes.bool,
  isTwitterWidgetEnabled: PropTypes.bool,
  isUserTrackingAnalyticsEnabled: PropTypes.bool,
  setCookieBoxVisiblity: PropTypes.func.isRequired
}

export default withTranslation(['common'])(CookiePolicy)
