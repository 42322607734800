import truncate from 'lodash/truncate';
import React from 'react';
import sanitizeHtml from 'sanitize-html';

/** Remove all dangerous html tags like <script></script>, <iframe></iframe> etc.
 * accept only a handful html tags
 * @param html string
 * @return JSX.Element
 */

type Props = {
  html: string;
  allowTags?: Array<string>;
  truncateLength?: number;
};

export const sanitize = (html, allowTags): string => {
  const opts = {
    allowedTags: allowTags
      ? allowTags
      : [
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'blockquote',
          'p',
          'a',
          'ul',
          'ol',
          's',
          'u',
          'nl',
          'li',
          'b',
          'i',
          'strong',
          'em',
          'strike',
          'code',
          'hr',
          'br',
          'div',
          'table',
          'thead',
          'caption',
          'tbody',
          'tr',
          'th',
          'td',
          'pre',
          'img',
          'span',
          'style',
        ],
    allowedAttributes: {
      span: ['class', 'style'],
      a: ['class', 'style', 'href', 'target'],
      img: ['class', 'style', 'src', 'width', 'height', 'alt'],
      '*': [
        'class',
        'style',
        'src',
        'target',
        'alt',
        'width',
        'height',
        'type',
        'tabindex',
        'role',
        'aria-hidden',
        'id',
      ],
    },
    selfClosing: ['img', 'br', 'hr'],
  };

  return sanitizeHtml(html, opts);
};

const HTMLWrapper: React.FC<Props> = ({ html, allowTags, truncateLength }) => {
  // allowTags and truncateLength are optional
  // allowtags helps to allow specific tags only
  // truncate length will truncate the text after sanitizing the html

  return html ? (
    truncateLength ? (
      <span
        dangerouslySetInnerHTML={{
          __html: truncate(sanitize(html, allowTags), {
            length: truncateLength,
          }),
        }}
      />
    ) : (
      <span dangerouslySetInnerHTML={{ __html: sanitize(html, allowTags) }} />
    )
  ) : (
    <></>
  );
};

export default HTMLWrapper;
