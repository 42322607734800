/**
 *
 * @param {bool} condition pattern/condition to be checked
 * @param {object} wrap wraper element
 * @param {object} children child element to be wrapped
 */

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : <>{children}</>

export default ConditionalWrap
