import { connect } from 'react-redux'

import CookiePolicy from 'components/cookie-policy'

const mapStateToProps = state => ({
  isFacebookSdkEnabled: state.features.features.hasOwnProperty('disable_facebook_sdk') &&
    !state.features.features.disable_facebook_sdk.enabled,
  isTwitterWidgetEnabled: state.features.features.hasOwnProperty('disable_twitter_widget') &&
    !state.features.features.disable_twitter_widget.enabled,
  isUserTrackingAnalyticsEnabled: state.features.features.hasOwnProperty('disable_google_analytics') &&
    !state.features.features.disable_google_analytics.enabled,
  isCookiePolicyEnabled: state.features.features.cookie_policy.enabled
})

export default connect(mapStateToProps)(CookiePolicy)
