import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  VisuallyHidden,
  useDisclosure,
  Stack
} from '@chakra-ui/react';
import { withTranslation } from 'i18n';
import Cookies from 'js-cookie';
import HTMLWrapper from 'lib/html-wrapper';
import { TFunction, WithTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CheckCircle from 'static/assets/images/check-circle.png';
import css from 'styled-jsx/css';

interface CookieConsent extends WithTranslation {
  t: TFunction;
  isGoogleAnalyticsEnabled: boolean;
  isUserTrackingAnalyticsEnabled: boolean;
  brandColor: string;
}

const { className, styles } = css.resolve`
  .disabled-image {
    height: 32px;
  }
  .modal-content {
    color: black;
    background-color: white !important;
  }
  .secondary-button {
    margin-right: 10px;
    font-weight: 500;
    background-color: white;
    color: #595959;
    border: 1px solid #d9d9d9;
  }
  .confirm-button {
    font-weight: 500;
    color: white;
  }
  .cookie-consent {
    height: auto;
    width: 100%;
    bottom: 0px;
    position: fixed;
    background-color: white;
    padding: 2rem;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);
    z-index: 999;
  }
  .manage-cookies {
    font-size: 16px;
    font-weight: bold;
    color: #262626;
  }
`;

const CookieConsentModal = ({
  t,
  isGoogleAnalyticsEnabled,
  isUserTrackingAnalyticsEnabled,
  brandColor
}: CookieConsent) => {
  const [showManageAllContent, setShowManageAllContent] = useState(false);
  const [showDrawer, setShowDrawer] = useState(true);
  const [isAnalyticsCookieEnabled, setAnalyticsCookie] = useState(false);

  const cookiesList = [
    { name: 'essential', status: true },
    { name: 'analytics', status: isAnalyticsCookieEnabled },
  ];

  const setCookieConsent = () => {
    // for setting the cookie consent cookie and closing the modal
    const currentDate = new Date();

    // Creating a expiry date for the cookie which is exactly after two years
    currentDate.setMonth(currentDate.getMonth() + 24);

    const cookieObject = {
      functional: true,
      analytics: isAnalyticsCookieEnabled,
    };

    // no http-only setting as it won't be able to access it from the client side
    Cookies.set('cookie_consent', cookieObject, {
      expires: currentDate,  // Ensure currentDate is a Date object
      path: "/",
      secure: true,          // Cookie will only be sent over HTTPS
      sameSite: "strict"     // Prevents the cookie from being sent in cross-site requests
    });


    const setGoogleAnalyticsStorage =
      (isUserTrackingAnalyticsEnabled || isGoogleAnalyticsEnabled) &&
      isAnalyticsCookieEnabled;
    if (setGoogleAnalyticsStorage) {
      // gtag -> is from the third party google analytics
      // @ts-ignore
      gtag('consent', 'update', { analytics_storage: 'granted' });
    }
    setShowDrawer(false);
    setShowManageAllContent(false);
  };

  const handleChange = (cookieName, value) => {
    if (cookieName === 'analytics') {
      setAnalyticsCookie(value);
    }
  };

  const renderToggle = (cookie) => {
    if (cookie.name === 'essential') {
      return (
        <img
          alt="check-circle"
          className={`${className} disabled-image`}
          src={CheckCircle}
          aria-hidden="true"
        />
      );
    }

    return (
      <>
        <VisuallyHidden>
          {t(`common:common.cookies.${cookie.name}Cookies`)}
        </VisuallyHidden>
        <Switch
          isChecked={cookie.status}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(cookie.name, e.target.checked)
          }
        />
      </>
    );
  };

  const renderCookies = () => {
    return cookiesList.map((cookie, index) => {
      return (
        <div key={index}>
          <div className="ehq-flex ehq-items-center ehq-justify-between">
            <Box className="ehq-w-4/5">
              <p className="ehq-font-bold">
                {t(`common:common.cookies.${cookie.name}Cookies`)}
              </p>
              <p>
                {t(`common:common.cookies.${cookie.name}CookiesDescription`)}
              </p>
            </Box>

            <Box>{renderToggle(cookie)}</Box>
          </div>
          <hr className="ehq-mt-4" />
        </div>
      );
    });
  };

  const handleSwitchModalAction = () => {
    setShowDrawer(!showDrawer);
    setShowManageAllContent(!showManageAllContent);
  };

  // Modal state
  const { onClose } = useDisclosure()

  const getManageCookieModal = () => {
    return (
      <Modal
        isOpen={showManageAllContent}
        closeOnOverlayClick={false}
        blockScrollOnMount
        size="xl"
        onClose={handleSwitchModalAction}
      >
        <ModalOverlay />
        <ModalContent className={`${className} modal-content`}>
          <ModalHeader>
            <h2>{t('common:common.cookies.manageCookies')}</h2>
          </ModalHeader>
          <ModalBody>
            <HTMLWrapper
              html={t('common:common.cookies.manageCookiesDescription')}
            />
            {renderCookies()}
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button
              backgroundColor={brandColor}
              className={`${className} confirm-button ehq-mr-3`}
              onClick={setCookieConsent}
            >
              {t('common:common.cookies.save&Close')}
            </Button>
            <Button
              key="cancel"
              className={`${className} secondary-button`}
              onClick={handleSwitchModalAction}
            >
              {t('common:common.cancel')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <>
      {getManageCookieModal()}
      <Drawer
        placement={'bottom'}
        isOpen={showDrawer}
        closeOnOverlayClick={false}
        size="lg"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent className={`${className} cookie-consent`}>
          <DrawerHeader p={0}>
            <h2 className={`${className} manage-cookies`}>
              {' '}
              {t('common:common.cookies.descriptionOne')}{' '}
            </h2>
          </DrawerHeader>
          <DrawerBody p={0}>
            <HTMLWrapper html={t('common:common.cookies.descriptionTwo')} />
            <div className="ehq-pt-8">
            <Stack direction={['column', 'row']} spacing={2}>
                <Button
                  backgroundColor={brandColor}
                  mr={3}
                  onClick={setCookieConsent}
                  className={`${className} confirm-button`}
                >
                  {t('common:common.cookies.cookieConfirm')}
                </Button>
                <Button
                  className={`${className} secondary-button`}
                  onClick={handleSwitchModalAction}
                >
                  {t('common.cookies.manageCookies')}
                </Button>
              </Stack>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {styles}
    </>
  );
};

CookieConsentModal.propTypes = {
  t: PropTypes.func.isRequired,
  isGoogleAnalyticsEnabled: PropTypes.bool,
  isUserTrackingAnalyticsEnabled: PropTypes.bool,
};

export default withTranslation(['common'])(CookieConsentModal);
