module.exports = [
  {
    key: 'open_sans',
    name: 'Open Sans',
    src: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'
  },
  {
    key: 'roboto',
    name: 'Roboto',
    src: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
  },
  {
    key: 'lato',
    name: 'Lato',
    src: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'lora_serif',
    name: 'Lora',
    src: 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap'
  },
  {
    key: 'source_sans_pro',
    name: 'Source Sans Pro',
    src: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap'
  },
  {
    key: 'ubuntu',
    name: 'Ubuntu',
    src: 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap'
  },
  {
    key: 'merriweather_serif',
    name: 'Merriweather',
    src: 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'playfair_serif',
    name: 'Playfair Display',
    src: 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
  },
  {
    key: 'exo_2',
    name: 'Exo 2',
    src: 'https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
  },
  {
    key: 'arial',
    name: 'Arial',
    src: ''
  },
  {
    key: 'helvetica',
    name: 'Helvetica',
    src: ''
  },
  {
    key: 'montserrat',
    name: 'Montserrat',
    src: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
  },
  {
    key: 'bree',
    name: 'Bree',
    src: 'https://use.typekit.net/tll6nyp.css'
  },
  {
    key: 'fira_sans',
    name: 'Fira Sans',
    src: 'https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
  },
  {
    key: 'poppins',
    name: 'Poppins',
    src: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
  },
  {
    key: 'roboto_slab',
    name: 'Roboto Slab',
    src: 'https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap'
  },
  {
    key: 'questrial',
    name: 'Questrial',
    src: 'https://fonts.googleapis.com/css2?family=Questrial:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'didact_gothic',
    name: 'Didact Gothic',
    src: 'https://fonts.googleapis.com/css2?family=Didact+Gothic:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'ropa_sans',
    name: 'Ropa Sans',
    src: 'https://fonts.googleapis.com/css2?family=Ropa+Sans:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'gudea',
    name: 'Gudea',
    src: 'https://fonts.googleapis.com/css2?family=Gudea:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'varela_round',
    name: 'Varela Round',
    src: 'https://fonts.googleapis.com/css2?family=Varela+Round:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'noto_sans',
    name: 'Noto Sans',
    src: 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'roboto_condensed',
    name: 'Roboto Condensed',
    src: 'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap'
  },
  {
    key: 'oswald',
    name: 'Oswald',
    src: 'https://fonts.googleapis.com/css2?family=Oswald:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'raleway',
    name: 'Raleway',
    src: 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
  },
  {
    key: 'titillium_web',
    name: 'Titillium Web',
    src: 'https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap'
  },
  {
    key: 'yanone_kaffeesatz',
    name: 'Yanone Kaffeesatz',
    src: 'https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'libre_baskerville_serif',
    name: 'Libre Baskerville',
    src: 'https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'knewave',
    name: 'Knewave',
    src: 'https://fonts.googleapis.com/css?family=Knewave:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'assistant',
    name: 'Assistant',
    src: 'https://fonts.googleapis.com/css2?family=Assistant:wght@0, 100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap'
  },
  {
    key: 'cabin',
    name: 'Cabin',
    src: 'https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap'
  },
  {
    key: 'aleo',
    name: 'Aleo',
    src: '//fonts.googleapis.com/css?family=Aleo:300,400,700&display=swap'
  },
  {
    key: 'mulish',
    name: 'Mulish',
    src: '//fonts.googleapis.com/css?family=Mulish:300,400,700&display=swap'
  },
  {
    key: 'red_hat_display',
    name: 'Red Hat Display',
    src: 'https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
  },
  {
    key: 'barlow_condensed',
    name: 'Barlow Condensed',
    src: 'https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
  },
  {
    key: 'inter',
    name: 'Inter',
    src: 'https://fonts.googleapis.com/css2?family=Inter&display=swap'
  },
  {
    key: 'outfit',
    name: 'Outfit',
    src: 'https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap'
  },
  {
    key: 'libre_franklin',
    name: 'Libre Franklin',
    src: 'https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;700&display=swap'
  },
  {
    key: 'lexend_deca',
    name: 'Lexend Deca',
    src: 'https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;700&display=swap'
  },
  {
    key: 'alegreya_sans_sc',
    name: 'Alegreya Sans SC',
    src: 'https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@400;700&display=swap'
  }
]
